$grid-breakpoints: (
  xs: 0,
  sm: 414px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$desktop_font_size: 16px;
$tablet_font_size: 14px;
$mobile_font-size: 12px;
$scale_factor_heading: 1.25;

$blackglass: rgba(19, 30, 41, 0.6); //$usana-grey-900
$tintedglass: rgba(0, 0, 0,  .2); //$usana-black-100
$vintageglass: rgba(0, 0, 0, 0.05); //$usana-black-100

$menu-mobile-width: 290px;
$menu-mobile-negative-margin: -290px;

$locale-chooser-width: 320px;
$locale-chooser-negative-margin: -320px;
