@import './variables';
@import './mixins';

// bootstrap imports
@import 'bootstrap/scss/functions';

//override variables
$enable-dark-mode: false;
@import 'bootstrap/scss/variables';

@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

@import 'bootstrap/scss/root';
//alert
//badge
@import 'bootstrap/scss/breadcrumb';
//button group
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/forms/form-control';
@import 'bootstrap/scss/forms/input-group';

@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/modal';

@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/transitions';

// Helpers
@import 'bootstrap/scss/helpers';

// Utilities
@import 'bootstrap/scss/utilities/api';

@import '../../../../src/assets/fonts/font.css';
@import '@usana/ux/sass/style-params';

@import './original-styles';

b,
strong {
  font-weight: bold !important;
}

a {
  color: $usana-blue-400;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

a.banner-policy-link {
  color: $usana-white-000;
}

body .optanon-alert-box-wrapper .optanon-alert-box-button-middle {
  background-color: $usana-blue-400;
  border-color: $usana-blue-400;
}

html,
.root {
  font-size: 16px;
  line-height: 22px;
}

.a11y-text,
.a11y-link {
  position: absolute;
  left: -9999px;
  z-index: 999999;
}
.a11y-link:focus {
  position: relative;
  left: 0;
}

.font-cn {
  font-family: 'NotoSansSC-Regular', sans-serif;
}

.main-hide-sticky-menu .clearTheNav {
  margin-top: 5.5rem !important;
}

.fp-modal {
  .card-body {
    padding-bottom: 0;
    padding-top: 0.75rem;
  }
}

.nav-block {
  height: 24px;
}

//for ao modal in the header
.ao-modal .modal-dialog {
  margin-top: 15%;

  .modal-header {
    border-bottom: 0;
  }

  .modal-body.text-center {
    text-align: left !important;
  }
}

.ao-modal-cn .modal-dialog {
  margin-top: 15%;
  .modal-header {
    background: url(^assets/zh-CN/images/shop-card1.jpg) center center no-repeat;
    background-size: cover;
    height: 300px;
  }
}

.modal-dialog {
  .modal-header {
    .close {
      opacity: 0.5;
      color: $usana-grey-900;
      cursor: pointer;
      font-size: 2rem;
      padding: 0;
      background-color: transparent;
      border: 0;
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
}

//for pr-modal in the product
.pr-modal {
  display: flex !important ;
  align-items: center;
  justify-content: center;
}

.social {
  display: flex;
  flex-direction: row;
}

u-choose-locale[menu-open='false'] .flag-icon {
  background-image: none; // don't load flag icons until the locale picker is opened
}

.flag-icon-uk {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="480" width="640" id="flag-icon-css-gb"> <defs> <clipPath id="a"> <path fill-opacity=".67" d="M-85.333 0h682.67v512h-682.67z"/> </clipPath> </defs> <g clip-path="url(#a)" transform="translate(80) scale(.94)"> <g stroke-width="1pt"> <path fill="#006" d="M-256 0H768.02v512.01H-256z"/> <path d="M-256 0v57.244l909.535 454.768H768.02V454.77L-141.515 0H-256zM768.02 0v57.243L-141.515 512.01H-256v-57.243L653.535 0H768.02z" fill="#fff"/> <path d="M170.675 0v512.01h170.67V0h-170.67zM-256 170.67v170.67H768.02V170.67H-256z" fill="#fff"/> <path d="M-256 204.804v102.402H768.02V204.804H-256zM204.81 0v512.01h102.4V0h-102.4zM-256 512.01L85.34 341.34h76.324l-341.34 170.67H-256zM-256 0L85.34 170.67H9.016L-256 38.164V0zm606.356 170.67L691.696 0h76.324L426.68 170.67h-76.324zM768.02 512.01L426.68 341.34h76.324L768.02 473.848v38.162z" fill="#c00"/> </g> </g> </svg>');
}

.flag-icon-mx {
  background-image: url('^assets/mx.png') !important;
}

.flag-icon-es {
  background-image: url('^assets/es.png') !important;
}

.u-side-modal {
  .modal-dialog {
    position: fixed;
    width: 500px;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    top: 0;
    right: -500px;
    bottom: 0;
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;

    .modal-content {
      height: 100%;
      border: none;
      border-radius: 0;
      overflow-y: auto;
    }
  }

  &.show .modal-dialog {
    right: 0;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }
}
