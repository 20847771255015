@import './variables';
@import './mixins';
@import '@usana/ux/sass/style-params';

body {
  @include font-body;
  color: $usana-grey-900;
}

/* Heading Text Sizes */
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  word-break: break-word;
}

.pando-styles {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    text-transform: none;
  }

  h1 {
    @include font-heading-1-mobile;

    @include media-breakpoint-up(md) {
      @include font-heading-1-desktop;
    }
  }
  h2 {
    @include font-heading-2-mobile;

    @include media-breakpoint-up(md) {
      @include font-heading-2-desktop;
    }
  }
  h3 {
    @include font-heading-3-mobile;

    @include media-breakpoint-up(md) {
      @include font-heading-3-desktop;
    }
  }
  h4 {
    @include font-heading-4;
  }
  h5 {
    @include font-heading-5;
  }
  h6 {
    @include font-heading-6;
  }

  .body-text-1,
  p {
    @include font-body;
  }

  .body-text-2 {
    @include font-body-2;
  }

  .body-text-3 {
    @include font-body-3;
  }
}
