@font-face {
  font-family: 'NotoSansSC-Black';
  src: url('NotoSansSC-Black.otf');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'NotoSansSC-Bold.otf';
  src: url('NotoSansSC-Bold.otf');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'NotoSansSC-DemiLight';
  src: url('NotoSansSC-DemiLight.otf');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'NotoSansSC-Light';
  src: url('NotoSansSC-Light.otf');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'NotoSansSC-Medium';
  src: url('NotoSansSC-Medium.otf');
  font-display: swap;
}
@font-face {
  font-family: 'NotoSansSC-Regular';
  src: url('NotoSansSC-Regular.otf');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'NotoSansSC-Thin';
  src: url('NotoSansSC-Thin.otf') ;
  font-style: normal;
  font-display: swap;
}
