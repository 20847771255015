@import './variables';
@import './mixins';
@import '../../../shared/src/sass/styles';
@import '@usana/ux/sass/style-params';
@import './typography';

.baoying {
  -webkit-font-smoothing: antialiased;
  .teamusana-logo-flex .logo-flex-item {
    &:nth-child(7) {
      display: none !important;
    }
  }

  // naver
  .desktop-logo {
    width: 313px;
    padding-right: 1.5rem;
  }

  .boss-inner {
    // .quote-block-cn
    :nth-child(5) {
      display: none !important;
    }
  }

  .be-block:nth-child(1) .be-quote-container {
    display: none !important;
  }

  .tech-content {
    border-bottom: 1px solid $usana-silver-100;
    border-top-width: 0 !important;
    padding-top: 55px !important;
    margin-top: 0 !important;
  }

  .card-text1 {
    line-height: 1.2 !important;
  }
  .card-text2 {
    overflow: hidden !important;
    display: -webkit-box !important;
    text-overflow: ellipsis !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
  }

  // .flex-item-cn
  u-flippy-grid .flex-item {
    cursor: pointer;
    display: block !important;
    flex: 0 0 262px;
    margin: 10px;
    overflow: hidden;

    &:nth-child(n + 2):nth-child(-n + 5):not(:first-child) {
      order: 1;
    }

    &:nth-child(n + 5):nth-child(-n + 15) {
      order: 3;
    }
    &:nth-child(n + 15):nth-child(-n + 19):not(:nth-child(15)) {
      order: 5;
    }

    &:first-child {
      align-self: center;
      border: solid $usana-grey-100 2px;
      border-radius: 10px;
      flex-basis: 806px !important;
      justify-self: center;
      order: 2;
    }

    &:nth-child(20) {
      align-self: center;
      border: solid $usana-grey-100 2px;
      border-radius: 10px;
      flex-basis: 806px;
      justify-self: center;
      order: 4;
    }
  }

  .flex-item > u-flippy-card {
    height: 400px;
  }

  .header {
    font-size: 36px;
    color: $usana-grey-300;
  }

  .subheading {
    margin-top: -20px;
    font-size: 24px;
    color: $usana-grey-300;
    letter-spacing: 0.48px;
    line-height: 32px;
  }

  .item {
    margin-top: 2rem;
  }

  .item-heading {
    font-size: 24px;
    color: $usana-grey-800;
    letter-spacing: 2.67px;
  }
  .item-text {
    font-size: 18px;
    color: $usana-grey-800;
    line-height: 30px;
  }
  // 合规专栏
  .compliance-wrap {
    margin: 200px 0 88px 0;
    .compliance-images-box {
      margin-top: 72px;
    }
  }
  // 手机端样式
  @include media-breakpoint-down(sm) {
    .compliance-wrap {
      margin: 100px 0 44px 0;
      .compliance-images-box {
        margin-top: 36px;
      }
    }
  }
}

// give-back-solution
u-give-back-solution {
  .solutions-container {
    padding-bottom: 0 !important;
    margin-bottom: -9px;
  }
  .header {
    margin-top: 9px;
  }
  .subheading {
    margin: -20px auto 0;
    width: 952px;
  }
}

// give-me-mission
u-give-back-mission {
  .header {
    h2 {
      font-size: 36px;
      color: $usana-grey-500;
      margin-bottom: 10px;
    }
    img {
      margin-top: -30px;
      margin-bottom: 0;
    }
  }
}

.rowWithVideo {
  margin-top: 5.3rem;
  .text-cn {
    margin-top: 3rem;
    font-size: 18px;
    line-height: 30px;
  }
  .col-lg-6 {
    margin-bottom: 45px;
    video {
      z-index: 0;
    }
  }
}

// give-back-impact
.thf-flex-cards {
  overflow-x: auto;
  margin-bottom: 54px;
  a {
    width: 173px;
    height: 38px;
    padding: 10px;
    margin-top: 20px;
  }
  h3 {
    line-height: 1.2;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  p {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .card-container {
    padding-bottom: 24px !important;
    margin-bottom: 24px;
  }
}

u-give-back-impact {
  .heading {
    padding-top: 70px;
  }
}

u-today-baoying {
  .header {
    font-size: 42px;
    color: $usana-grey-800;
    padding-top: 90px;
  }

  .subheading {
    line-height: 28px;
    font-size: 20px;
    text-align: center;
  }
  .thf-flex-cards {
    padding-bottom: 36px;
  }
}

@include media-breakpoint-down(sm) {
  .baoying {
    .flex-item {
      &:first-child {
        flex-basis: 512px;
        order: -1;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  u-give-back-solution {
    .container {
      .text-center {
        padding-bottom: 8px !important;
        .header {
          font-size: 26px;
        }
        .subheading {
          font-size: 16px;
          width: 282px;
        }
      }
      .u-media {
        margin-bottom: 1.5rem;
        .item {
          margin-top: 0;
          .item-heading {
            width: 70%;
            text-align: center;
            font-size: 20px;
            margin-top: 0;
            margin-bottom: 12px;
          }
          .item-text {
            font-size: 14px;
            width: 97%;
          }
        }
      }
    }
  }

  u-give-back-impact {
    .container {
      padding-bottom: 27px !important;

      .header {
        margin-top: 10px;
        font-size: 26px !important;
      }
      .subheading {
        font-size: 16px !important;
        width: 82%;
        margin: -20px auto 0;
      }
    }
  }

  u-today-baoying {
    .container {
      padding-bottom: 12px !important;

      .header {
        font-size: 26px;
        color: $usana-grey-600;
        padding-top: 36px;
        margin: 0;
      }
      .subheading {
        line-height: 22px;
        font-size: 1rem;
        padding: 12px 50px;
        margin: 0;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .thf-flex-cards {
    display: block;
    margin-bottom: 0;
    .card-container {
      margin: 5px auto 32px !important;
    }
  }
}

.hero-content {
  height: 350px;
  border-radius: 4px;
  font-family: PingFangSC-Semibold;
  max-width: 900px;
  .hero-text {
    div {
      h2 {
        margin-top: 50px;
        font-size: 40px;
        font-weight: 540;
        letter-spacing: 5px;
      }
      div {
        h3 {
          font-size: 32px;
          font-weight: 300;
          line-height: 3rem;
        }
        margin-top: 50px;
      }
      margin-top: 50px;
    }
    h5 {
      font-size: 35px;
      margin-top: 3%;
      margin-bottom: 2%;
      text-align: center;
    }
    span {
      div {
        float: left;
        margin-top: 0;
        line-height: 190%;
        font-size: 21px;
      }
    }
    padding-left: 1%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1366px) {
  .hero-content {
    height: 240px;
    border-radius: 4px;
    font-family: PingFangSC-Semibold;
    clear: both;
    .hero-text {
      div {
        h2 {
          margin-top: 20px;
          font-size: 30px;
          font-weight: 540;
          letter-spacing: 5px;
        }
        div {
          h3 {
            font-size: 20px;
            font-weight: 300;
            display: inline;
          }
          margin-top: 24px;
          width: 84%;
          margin-left: 7%;
          line-height: 34px;
        }
        margin-top: 28%;
      }
      h5 {
        font-size: 20px;
        margin-top: 5%;
        margin-bottom: 2%;
        text-align: center;
      }
      span {
        div {
          font-size: 11px;
        }
      }
    }
    .hero-text-cn {
      padding-top: 22px;
      height: 80px;
    }
    .hero-modal {
      margin-top: -26px;
    }
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 991px) {
  .hero-content .hero-text span div {
    font-size: 13px;
  }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 991px) {
  .hero-content .hero-text span div {
    font-size: 14px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .hero-content {
    .hero-text {
      div {
        h2 {
          margin-top: 90px;
          font-size: 40px;
          font-weight: 540;
          letter-spacing: 5px;
        }
        div {
          h3 {
            font-size: 32px;
            font-weight: 300;
            line-height: 5rem;
          }
        }
        margin-top: 50px;
      }
      h5 {
        font-size: 35px;
        margin-top: 3%;
        margin-bottom: 2%;
        text-align: center;
      }
      span {
        div {
          float: left;
          margin-top: 0px;
          line-height: 190%;
          font-size: 20px;
        }
      }
      padding-left: 1%;
    }
  }
}

@media (min-device-width: 1024px) and (max-device-width: 1366px) {
  .hero-content .hero-text div h2 {
    margin-top: 50px;
  }
}

@media (max-device-width: 1366px) {
  .hero-inner {
    height: 100vh;
  }
}
