b,
strong {
  font-weight: bold !important;
}

a {
  color: $usana-blue-400;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

a.banner-policy-link {
  color: $usana-white-000;
}

body .optanon-alert-box-wrapper .optanon-alert-box-button-middle {
  background-color: $usana-blue-400;
  border-color: $usana-blue-400;
}

html,
.root {
  font-size: 16px;
  line-height: 22px;
}

body {
  font-family: 'Noto Sans', sans-serif;
  font-size: $desktop_font_size;
  line-height: 24px;
  margin: auto;
  @include media-breakpoint-down(md) {
    font-size: $tablet_font_size;
    line-height: 21px;
  }
  @include media-breakpoint-down(sm) {
    font-size: $mobile_font-size;
    line-height: 18px;
  }
}

.a11y-text,
.a11y-link {
  position: absolute;
  left: -9999px;
  z-index: 999999;
}
.a11y-link:focus {
  position: relative;
  left: 0;
}

.font-cn {
  font-family: 'NotoSansSC-Regular', sans-serif;
}

h1,
.h1 {
  margin-top: 1.375rem;
  text-transform: uppercase;
}
h2,
.h2 {
  margin-top: 1.375rem;
  text-transform: uppercase;
}

h3,
.h3 {
  margin-bottom: 1.375rem;
  margin-top: 1.375rem;
}

h4,
.h4 {
  margin-bottom: 0;
  margin-top: 1.375rem;
}

h5,
.h5 {
  margin-bottom: 0;
  margin-top: 1.375rem;
}

p,
ul,
ol,
pre,
table,
blockquote {
  margin-bottom: 1.375rem;
  margin-top: 0;
}

ul {
  ul,
  ol {
    margin-bottom: 0;
    margin-top: 0;
  }
}

ol {
  ul,
  ol {
    margin-bottom: 0;
    margin-top: 0;
  }
}

hr,
.hr {
  border: 1px solid;
  margin: -1px 0;
}

sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

h1 {
  font-size: 2em;
  font-weight: 100;
  line-height: 1.4em;

  &.strong {
    font-weight: 700;
  }
}

section {
  padding: 25px 0;
}

.btn:not(.ignore-btn-rounding) {
  border-radius: 23px;
}

.btn-primary {
  background-color: $usana-grey-900;
  border-color: $usana-grey-900;
  color: $usana-white-000;
  font-size: 0.8rem;
  letter-spacing: 0.15em;
  padding: 1em 2em;
  text-transform: uppercase;
  &:hover {
    background-color: transparent;
    border-color: $usana-grey-900;
    color: $usana-grey-900;
  }
  &:not([disabled]):not(.disabled):active {
    background-color: $usana-grey-900;
    border-color: $usana-grey-900;
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }
}

.btn-ghost {
  background-color: transparent;
  border-color: $usana-white-000;
  color: $usana-white-000;
  font-size: 0.8rem;
  letter-spacing: 0.15em;
  padding: 1em 2em;
  text-transform: uppercase;
  min-width: 256px;
  &:hover {
    background-color: $usana-grey-900;
    border-color: $usana-grey-900;
    color: $usana-white-000;
  }
}

.btn-default {
  border: solid $usana-grey-900 1px;
  border-radius: 23px;
  color: $usana-grey-900;
  font-weight: 300;
  padding: 0.4em 3em;
}

@include media-breakpoint-up(md) {
  .hideformobile {
    display: block;
  }
  .hidefordesktop {
    display: block;
  }
}

@include media-breakpoint-up(lg) {
  h1,
  .h1 {
    font-size: 5.3594rem;
    line-height: 6.875rem;
    margin-top: 1.375rem;
    margin-bottom: 2.75rem;
  }

  h2,
  .h2 {
    font-size: 3.0625rem;
    line-height: 4.125rem;
    margin-top: 1.375rem;
    margin-bottom: 1.375rem;
  }

  h3,
  .h3 {
    font-size: 1.75rem;
    line-height: 2.3625rem;
    margin-top: 1.375rem;
    margin-bottom: 1.375rem;
  }
  //base * 1.75; LH = 1.75 * 1.35 (minor 7th x
  h4,
  .h4 {
    font-size: 1rem;
    line-height: 1.35rem;
    margin-top: 1.375rem;
    margin-bottom: 0;
  }
  //base
  h5,
  .h5 {
    font-size: 1rem;
    line-height: 1.35rem;
    margin-top: 1.375rem;
    margin-bottom: 0;
  }
  p,
  ul,
  ol,
  pre,
  table,
  blockquote {
    margin-top: 0;
    margin-bottom: 1.375rem;
  }
  ul ul,
  ol ol,
  ul ol,
  ol ul {
    margin-top: 0;
    margin-bottom: 0;
  }
  /* Let's make sure all's aligned */
  hr,
  .hr {
    border: 1px solid;
    margin: -1px 0;
  }
  sub,
  sup {
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sup {
    top: -0.5em;
  }
  sub {
    bottom: -0.25em;
  }
  section {
    background-color: $usana-white-000;
    padding: 55px 0 !important;
    width: 100% !important;
  }
  .show-mobile-only {
    display: none;
  }
}

h1 strong,
h2 strong,
h3 strong {
  font-weight: 700;
}

.main-hide-sticky-menu .clearTheNav {
  margin-top: 5.5rem !important;
}

@include media-breakpoint-down(lg) {
  body.blocked-by-mobile-menu {
    overflow: hidden;
  }
}

@include media-breakpoint-up(lg) {
  body.blocked-by-desktop-menu {
    overflow: hidden;
  }
}

@include media-breakpoint-down(md) {
  .show-desktop-only {
    display: none;
  }

  .show-mobile-only {
    padding: 10px 20px;
    text-align: center;
    display: block;
  }
}

.w-200 {
  width: 200%;
}

.w-300 {
  width: 300%;
}

.w-400 {
  width: 400%;
}

.environmental-responsibility > div[id] {
  margin-bottom: 1rem;
}

.environmental-responsibility #text1 {
  // styling as an H2
  font-size: 2rem;
  font-weight: 700;
  line-height: 3.236rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

// "media" was removed in bootstrap 5. this works the same.
.u-media {
  display: flex;
  width: 100%;
  gap: 0.5rem;
  align-items: flex-start;
  background-color: transparent;
  border-color: transparent;
}

html {
  font-size: $desktop_font_size;
  line-height: 24px;

  h1,
  h2 {
    font-weight: 700;
  }

  h1 {
    font-size: 1rem * $scale_factor_heading * 2;
    line-height: 1rem * $scale_factor_heading * 2.5;
    margin-bottom: 1.5rem * 2;
    margin-top: 1.5rem;

    @include media-breakpoint-down(md) {
      font-size: 1rem * $scale_factor_heading * 1.8;
      line-height: 1rem * $scale_factor_heading * 2.25;
    }
  }

  h2 {
    font-size: 1rem * $scale_factor_heading * 1.6;
    line-height: 1rem * $scale_factor_heading * 2;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    @include media-breakpoint-down(md) {
      font-size: 1rem * $scale_factor_heading * 1.4;
      line-height: 1rem * $scale_factor_heading * 2;
    }
  }

  h3 {
    font-size: 1rem * $scale_factor_heading * 1.2;
    line-height: 1rem * $scale_factor_heading * 1.5;

    @include media-breakpoint-down(md) {
      font-size: 1rem * $scale_factor_heading;
      line-height: 1rem * $scale_factor_heading * 1.25;
    }
  }

  h3,
  h4 {
    font-weight: 400;
    margin-top: 1.5rem;
  }

  p {
    margin-bottom: 1.5rem;
    font-size: $desktop_font_size;
    line-height: 24px;
    @include media-breakpoint-down(md) {
      font-size: $tablet_font_size;
      line-height: 21px;
    }
    @include media-breakpoint-down(sm) {
      font-size: $mobile_font-size;
      line-height: 18px;
    }
  }
  h4 {
    font-size: 1rem * $scale_factor_heading * 0.9;
    line-height: 1rem * $scale_factor_heading * 1.125;
  }

  h5 {
    font-size: 1rem * $scale_factor_heading * 0.8;
    line-height: 1rem * $scale_factor_heading;
  }

  h6 {
    font-size: 1rem * $scale_factor_heading * 0.7;
    line-height: 1rem * $scale_factor_heading * 0.875;
  }
}
